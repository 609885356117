import React from "react"
import * as style from "./ProductSize.module.scss"
import * as PropTypes from "prop-types"
import { COMMON_SIZES, getDisplaySizesForSimpleProducts } from "../../utils/coloursAndSizes"

const ProductSize = ({ size, showLabel }) => {
  let shoes = /^(size)?\s?(\d{1,2})\b/i
  let volumes = /^\d+\s?ml/i
  let displaySize

  if (
    !COMMON_SIZES[size.toLowerCase()] &&
    !size.match(shoes) &&
    !size.match(volumes)
  ) {
    displaySize = size
  } else {
    displaySize = getDisplaySizesForSimpleProducts(size)
  }

  return (
    <div className={style.sizeBoxBlock}>
      {showLabel && (
        <p className={style.sizeBoxLable}>Size</p>
      )}
      <ul className={style.sizeOptionBox}>
        <li>
          <span>{displaySize.sizesToBuild ? displaySize.sizesToBuild[0].displaySize : displaySize}</span>
        </li>
      </ul>
    </div>
  )
}

ProductSize.propTypes = {
  showLabel: PropTypes.bool,
}

ProductSize.defaultProps = {
  showLabel: true
}

export default ProductSize
