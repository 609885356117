
// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import * as PropTypes from "prop-types"
import * as style from "./ProductColour.module.scss"

const ProductColour = ({ colour, showLabel, allColours }) => {

  const selectedColour = allColours[colour]
  let displayColour = "transparent"
  if (selectedColour !== undefined){
    displayColour = selectedColour.hexcode
  } else {
    console.error('FIXME productColour undefined for ' + colour)
  }

  let colourStyles

  if (selectedColour !== undefined && selectedColour.lightAccent) {
    colourStyles = `box-shadow: 0 0 0 3px white, 0 0 0 5px #e9e6e6;
    border: 1px solid #e9e6e6;`
  } else {
    colourStyles = `box-shadow: 0 0 0 3px white, 0 0 0 5px ${displayColour};`
  }

  return (
    <div className={style.colorSelectorBlock}>
      {showLabel && (
        <p className={style.colorSelectorLable}>Colour</p>
      )}
      <ul className={style.colorSelectorOptions}>
        <li
          className={style.colorOption}
          css={css`
          background-color: ${displayColour};
          ${colourStyles}`}/>
      </ul>
    </div>
  )
}

ProductColour.propTypes = {
  showLabel: PropTypes.bool.isRequired
}

ProductColour.defaultProps = {
  showLabel: true
}

export default ProductColour
