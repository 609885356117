// extracted by mini-css-extract-plugin
export var bgGrey100 = "ProductColour-module--bg-grey-100--2a793";
export var bgGrey150 = "ProductColour-module--bg-grey-150--26231";
export var bgGrey200 = "ProductColour-module--bg-grey-200--b4d51";
export var bgGrey300 = "ProductColour-module--bg-grey-300--ed3ed";
export var bgGrey400 = "ProductColour-module--bg-grey-400--c9d2f";
export var bgGrey500 = "ProductColour-module--bg-grey-500--1717f";
export var bgGrey600 = "ProductColour-module--bg-grey-600--6ab6a";
export var bgGrey700 = "ProductColour-module--bg-grey-700--3a2a4";
export var bgGrey800 = "ProductColour-module--bg-grey-800--571b9";
export var bgGrey900 = "ProductColour-module--bg-grey-900--bc373";
export var colorOption = "ProductColour-module--colorOption--49053";
export var colorSelectorBlock = "ProductColour-module--colorSelectorBlock--72de4";
export var colorSelectorLable = "ProductColour-module--colorSelectorLable--697ac";
export var colorSelectorOptions = "ProductColour-module--colorSelectorOptions--c0466";
export var textGrey100 = "ProductColour-module--text-grey-100--315c4";
export var textGrey150 = "ProductColour-module--text-grey-150--c46f8";
export var textGrey200 = "ProductColour-module--text-grey-200--4d0f2";
export var textGrey300 = "ProductColour-module--text-grey-300--055d6";
export var textGrey400 = "ProductColour-module--text-grey-400--b878e";
export var textGrey500 = "ProductColour-module--text-grey-500--2d97d";
export var textGrey600 = "ProductColour-module--text-grey-600--2a78d";
export var textGrey700 = "ProductColour-module--text-grey-700--f419c";
export var textGrey800 = "ProductColour-module--text-grey-800--46da1";
export var textGrey900 = "ProductColour-module--text-grey-900--e1bc7";