// extracted by mini-css-extract-plugin
export var bgGrey100 = "ProductSize-module--bg-grey-100--cbba4";
export var bgGrey150 = "ProductSize-module--bg-grey-150--64651";
export var bgGrey200 = "ProductSize-module--bg-grey-200--fc467";
export var bgGrey300 = "ProductSize-module--bg-grey-300--221dd";
export var bgGrey400 = "ProductSize-module--bg-grey-400--5a140";
export var bgGrey500 = "ProductSize-module--bg-grey-500--31428";
export var bgGrey600 = "ProductSize-module--bg-grey-600--50e76";
export var bgGrey700 = "ProductSize-module--bg-grey-700--798ec";
export var bgGrey800 = "ProductSize-module--bg-grey-800--236c2";
export var bgGrey900 = "ProductSize-module--bg-grey-900--4e731";
export var sizeBoxBlock = "ProductSize-module--sizeBoxBlock--39dd6";
export var sizeBoxLable = "ProductSize-module--sizeBoxLable--d325e";
export var sizeOptionBox = "ProductSize-module--sizeOptionBox--58841";
export var textGrey100 = "ProductSize-module--text-grey-100--396f8";
export var textGrey150 = "ProductSize-module--text-grey-150--e40fa";
export var textGrey200 = "ProductSize-module--text-grey-200--d5086";
export var textGrey300 = "ProductSize-module--text-grey-300--c1a52";
export var textGrey400 = "ProductSize-module--text-grey-400--a7990";
export var textGrey500 = "ProductSize-module--text-grey-500--82031";
export var textGrey600 = "ProductSize-module--text-grey-600--edf0e";
export var textGrey700 = "ProductSize-module--text-grey-700--a4223";
export var textGrey800 = "ProductSize-module--text-grey-800--7fe04";
export var textGrey900 = "ProductSize-module--text-grey-900--8ae06";