import React, {useEffect} from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { basketSelectors, basketActions } from "../state/basket"
import { dataLayerUpdate } from "../api/dataLayer/gaDataLayer"
import Layout from "../components/Layout"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import classNames from "classnames"

import Breadcrumb from "../components/UI/Breadcrumb/Breadcrumb"

import ProductImageSlider from "./ProductUI/ProductImageSlider"
import Price from "./ProductUI/Price"
import ProductColor from "./ProductUI/ProductColour"
import ProductSize from "./ProductUI/ProductSize"
import AddToBasket from "./ProductUI/AddToBasket"
import { ModalPortal } from "../portals/Modal.portal"
import AddedToBasketModal from "./ProductUI/AddedToBasketModal"
import BasketErrorModal from "./ProductUI/BasketErrorModal"
import OutOfStock from "./ProductUI/OutOfStock"
import Installments from "./ProductUI/Installments"
import Delivery from "./ProductUI/Delivery"
import SpecsSection from "./ProductUI/SpecsSection"
import UpsellSection from "./ProductUI/UpsellSection"
import {
  getTopLevelProductCategory,
  getBreadcrumbDataFromCategoryObj,
  setInstallments,
  getSliderImages,
  getSpecs,
  getSimpleProductColor,
  getProductSize,
  getProductBrand,
  getRequiredProductDataForGA,
} from "../utils/productsHelper"
import { getSeoAvailiability, makeSeoProductImages, makeProductSeoHeadTags, makeProductPageSchema } from "../utils/seoHelper"
import { convertFlatListToHierarchical } from "../utils/hierarchicalize"
import { slugify } from "../utils/slugify"
import { prop } from "ramda"
import {BeforeAddToBasketNotification} from "./ProductUI/BeforeAddToBasketNotification";
import { rawPrice as _rawPrice } from "../utils/pricesHelper"

const SimpleProduct = ({
 location,
 data,
 basket,
 isAddedToBasketModalVisible,
 hideAddedToBasketModal,
 isBasketError,
 hideBasketError,
 pageContext,
}) => {

  const product = JSON.parse(pageContext.product)

  const {
    id,
    name,
    productCategories,
    description,
    shortDescription,
    price,
    regularPrice,
    onSale,
    salePrice,
    stockStatus,
    stockQuantity,
    featuredImage,
    galleryImages,
    sku,
    attributes,
    upsell,
    product_videos: { youtubeVideoId },
    seo
  } = product

  product.slug = slugify(product.name)

  product.topLevelProductCategory = getTopLevelProductCategory(productCategories)

  const installments = setInstallments(
    product.topLevelProductCategory,
    _rawPrice(price)
  )
  let installmentsPrice
  if (installments) {
    installmentsPrice = (_rawPrice(price) / installments)
      .toFixed(2)
      .toLocaleString()
  }

  const sliderImages = getSliderImages(featuredImage, galleryImages)

  let specs
  if (attributes && attributes.nodes) {
    specs = getSpecs(attributes)
    product.colour = getSimpleProductColor(attributes)
    product.size = getProductSize(attributes)
    product.brand = getProductBrand(attributes)
  } else {
    console.warn(`# Crumbs! Simple product, ${name} (id: ${id}), has no attributes.`)
  }

  useEffect(() => {
    dataLayerUpdate("view_item", getRequiredProductDataForGA(product))
    return () => {}
  }, [])

  const categoryTree = convertFlatListToHierarchical(productCategories.nodes, {
    idKey: "id",
    childrenKey: "children",
    parentKey: "parentId",
  })

  let breadcrumbData
  let locationBreadcrumb

  locationBreadcrumb = prop("breadcrumbData", location.state)

  if (locationBreadcrumb) {
    breadcrumbData = location.state.breadcrumbData
  } else {
    breadcrumbData = getBreadcrumbDataFromCategoryObj(categoryTree)
  }

  const StockHandler = ({ stockQuantity, stockStatus }) => {
    const basketItem = basket.filter(item => item.itemId === id)
    let stockUpdate

    if (basketItem) {
      stockUpdate = prop("updatedStock", basketItem[0])
    }

    if (isBasketError) {
      return (
        <ModalPortal hide={hideBasketError} size="lg" centered={true}>
          <BasketErrorModal product={product} />
        </ModalPortal>
      )
    } else if (isAddedToBasketModalVisible) {
      return (
        <ModalPortal
          hide={hideAddedToBasketModal}
          size="lg"
          centered={true}
          heading="Added to basket">
          <AddedToBasketModal product={product} />
        </ModalPortal>
      )
    }

    if (stockQuantity === null && stockStatus === "IN_STOCK") {
      return <AddToBasket location={location} product={product} buttonTitle="Add to basket" />
    } else if(Boolean(Number(process.env.BACKORDER_ENABLED)) && stockStatus === "ON_BACKORDER") {
      return <AddToBasket location={location} product={product} />
    } else if (stockQuantity && !stockUpdate) {
      return <AddToBasket location={location} product={product} />
    } else if (stockUpdate > 0) {
      return <AddToBasket location={location} product={product}/>
    } else {
      return <OutOfStock product={product} location={location}  />
    }
  }


  let featuredProductInformation = product?.beforeAddToBasketNotification?.productPromotions
  const rawPrice = _rawPrice(price)

  const availability = getSeoAvailiability(stockStatus, stockQuantity)
  const seoImages = makeSeoProductImages(sliderImages)

  const productSeoHeadTags = makeProductSeoHeadTags(seo.fullHead, product.brand, availability.social, seoImages.social)
  const productPageSchema = makeProductPageSchema(
      seo.schema.raw,
      name,
      seoImages.schema,
      seo.metaDesc,
      sku,
      location.pathname,
      availability.schema,
      product.brand,
      rawPrice,
      onSale,
      breadcrumbData
  )

  return (
    <Layout
      location={location}
      seoHeadTags={productSeoHeadTags}
      schema={productPageSchema}>
      {breadcrumbData && <Breadcrumb data={breadcrumbData} />}
      <Container fluid="xl">
        <section className="py-5">
          <Row className={productDescriptionRowClass}>
            <Col
              className={productDescriptionImgColClass}
              sm={12}
              md={6}
              xl={5}>
              {sliderImages && (
                <ProductImageSlider onSale={onSale} simpleData={sliderImages} youtubeVideoId={youtubeVideoId}/>
              )}
            </Col>
            <Col
              className={productDescriptionTextColClass}
              sm={12}
              md={6}
              xl={6}>
              <h1 className={productNameClass}>{name}</h1>
              {regularPrice && (
                <Price
                  onSale={onSale}
                  regularPrice={regularPrice}
                  salePrice={salePrice && salePrice}
                  promotionDetails={featuredProductInformation}
                />
              )}
              {product.colour && <ProductColor colour={product.colour} allColours={pageContext.allColours} />}
              {product.size && <ProductSize size={product.size} />}

              <BeforeAddToBasketNotification product={product} basket={basket} />

              <StockHandler
                stockStatus={stockStatus}
                stockQuantity={
                  stockQuantity !== undefined ? stockQuantity : null
                }
              />

            {installments && Boolean(Number(process.env.IS_KLARNA_ENABLED)) && (
                <Installments
                  installments={installments}
                  installmentsPrice={installmentsPrice}
                />
              )}
              <Delivery />
              {shortDescription && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: shortDescription,
                  }}
                />
              )}
            </Col>
          </Row>
        </section>
        {attributes && attributes.nodes && (
          <SpecsSection
            category={product.topLevelProductCategory}
            description={description}
            specification={specs.specification}
            specificationLeft={specs.specificationLeft}
            specificationRight={specs.specificationRight}
          />
        )}
        {upsell.nodes && <UpsellSection upsells={upsell.nodes} />}
      </Container>
    </Layout>
  )
}

const productDescriptionRowClass = classNames(
    "d-flex",
    "justify-content-between"
)
const productDescriptionImgColClass = classNames("m-xl-0", "p-xl-0")
const productDescriptionTextColClass = classNames("my-3", "m-xl-0", "p-xl-0")
const productNameClass = classNames("text-uppercase")

const mapStateToProps = createStructuredSelector({
  basket: basketSelectors.getItems,
  isAddedToBasketModalVisible: basketSelectors.isAddedToBasketModalVisible,
  isBasketError: basketSelectors.isBasketError,
})
const mapDispatchToProps = {
  hideAddedToBasketModal: basketActions.hideAddedToBasketModal,
  hideBasketError: basketActions.hideBasketError,
}

export default connect(mapStateToProps, mapDispatchToProps)(SimpleProduct)
